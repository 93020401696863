import React from "react";
import Header from "./Header/Header";
import { Outlet } from "react-router-dom";
import Footer from "./Footer/Footer";
import ScrollToTop from "../common/ScrollToTop";
import useAuth from "../../hooks/useAuth";
// -------------------------------------------------------------------------------------------------------

const Layout = () => {
  // -------------------------------------------------States-------------------------------------------------
  // -------------------------------------------------------------------------------------------------------
  // -------------------------------------------------Hooks-------------------------------------------------
  const { isUserLoggedIn } = useAuth();
  // -------------------------------------------------------------------------------------------------------
  // ---------------------------------------------Functions-------------------------------------------------
  // -------------------------------------------------------------------------------------------------------
  // ------------------------------------------------useEffects----------------------------------------------
  // -------------------------------------------------------------------------------------------------------
  return isUserLoggedIn ? (
    <div className="d-flex flex-column justify-content-between">
      <Header />
      <ScrollToTop />
      <Outlet />
      <Footer />
    </div>
  ) : (
    <div className="d-flex flex-column justify-content-between">
      <Outlet />
    </div>
  );
};

export default Layout;
