import React from 'react'
import './banerskeleton.css'

const Banerskeleton = () => {
  return (
    <div>
    <span className="skeleton-loader"></span>   
 
  </div>
  )
}

export default Banerskeleton 