import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./UserProfile.css";
import { MdAddAPhoto } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../hooks/useAuth";
import defaultImage from "../../assets/images/defaultImage.png";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { updateUser } from "../../features/actions/authActions";
import { TailSpin } from "react-loader-spinner";
import { useEffect } from "react";
import { resetUserStatus } from "../../features/slices/authSlice";

const UserProfile = () => {
  // ----------------------------------useState--------------------------------------------------------
  const { loggedInUserData } = useAuth();

  const [profilePicture, setProfilePicture] = useState(
    loggedInUserData?.avatar || defaultImage
  );
  const [isEditable, setIsEditable] = useState(false);
  const [fullName, setFullName] = useState(loggedInUserData?.fullName || "N.A");
  const [email, setEmail] = useState(loggedInUserData?.email || "N.A");
  const [userName, setUserName] = useState(loggedInUserData?.userName || "N.A");
  const [mobile, setMobile] = useState(loggedInUserData?.mobile || "N.A");
  const [address, setAddress] = useState(loggedInUserData?.address || "N.A");
  const [avatar, setAvatar] = useState(loggedInUserData?.avatar || "");
  const [showProfilePicUpdateBtn, setShowProfilePicUpdateBtn] = useState(false);

  // -------------------------------------hooks--------------------------------------------------
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, isUserUpdated } = useSelector((state) => state?.auth);
  const courseStatus = useSelector((state) => state?.video?.courseStatus);
  // ----------------------------------------------------------------------------------------------
  // -------------------------------------functions------------------------------------------------
  // ----------------------------------------------------------------------------------------------

  // handleProfilePictureChange -- handler to change the profile picture
  const handleProfilePictureChange = (e) => {
    setShowProfilePicUpdateBtn(true);

    const file = e.target.files[0];

    setAvatar(file);

    let url = URL.createObjectURL(file);

    setProfilePicture(url);
  };

  const handleButtonClick = () => {
    document.getElementById("fileInput").click();
  };

  // updateProfile -- handler to call the api and update the profile picture
  const updateProfile = (e) => {
    try {
      confirmAlert({
        title: "NOTE!",
        message: "Are You Sure, You want to Update the Profile",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              if (loggedInUserData?._id) {
                const formData = new FormData();
                formData.append("payload", JSON.stringify({ fullName }));
                formData.append("avatar", avatar);
                dispatch(
                  updateUser({ payload: formData, id: loggedInUserData?._id })
                );
              } else {
                toast.error("No User Id Found, Try Again");
                navigate("/");
              }
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    } catch (error) {
      console.log(error.message);
      toast.error(error?.message);
    }
  };

  // --------------------------------------useEffect-----------------------------------------------
  useEffect(() => {
    if (isUserUpdated) {
      setShowProfilePicUpdateBtn(false);
      setIsEditable(false);
      resetUserStatus(false);
    }
  }, [isUserUpdated]);

  // ----------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------
  return (
    <>
      <div>
        <section className="user_profile">
          {isLoading && (
            <div className={`aftersubmitLoading`}>
              <TailSpin
                height="80"
                width="80"
                color="#4fa94d"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          )}
          <div className="container">
            <div className="main-body">
              <div className="row gutters-sm">
                <div className="col-md-4 mb-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex flex-column align-items-center text-center">
                        <div style={{ position: "relative" }}>
                          <img
                            src={profilePicture}
                            alt="No Image Found"
                            className="rounded-circle"
                            width={100}
                            height={100}
                            style={{ borderRadius: "50%" }}
                          />
                          <lable
                            for="fileInput"
                            onClick={() => handleButtonClick()}
                            className="inputpicture"
                          >
                            <MdAddAPhoto />
                          </lable>
                          <input
                            type="file"
                            accept="image/*"
                            id="fileInput"
                            onChange={(e) => handleProfilePictureChange(e)}
                          />
                        </div>

                        <div className="mt-3">
                          <h4>{fullName}</h4>
                          <p className="text-secondary mb-1">
                            {loggedInUserData?.role || "N.A"}
                          </p>

                          {showProfilePicUpdateBtn && (
                            <Button
                              type="button"
                              className="text-muted font-size-sm"
                              style={{
                                background: "pink",
                                border: "0px",
                                fontFamily: "poppins",
                                fontWeight: "bolder",
                              }}
                              onClick={updateProfile}
                            >
                              Update Profile Picture
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card mt-3">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                        <h6 className="mb-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-globe mr-2 icon-inline"
                          >
                            <circle cx={12} cy={12} r={10} />
                            <line x1={2} y1={12} x2={22} y2={12} />
                            <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z" />
                          </svg>
                          Website
                        </h6>
                        <span className="text-secondary">
                          https://bootdey.com
                        </span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                        <h6 className="mb-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-github mr-2 icon-inline"
                          >
                            <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22" />
                          </svg>
                          Github
                        </h6>
                        <span className="text-secondary">bootdey</span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                        <h6 className="mb-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-twitter mr-2 icon-inline text-info"
                          >
                            <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z" />
                          </svg>
                          Twitter
                        </h6>
                        <span className="text-secondary">@bootdey</span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                        <h6 className="mb-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-instagram mr-2 icon-inline text-danger"
                          >
                            <rect
                              x={2}
                              y={2}
                              width={20}
                              height={20}
                              rx={5}
                              ry={5}
                            />
                            <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />
                            <line x1="17.5" y1="6.5" x2="17.51" y2="6.5" />
                          </svg>
                          Instagram
                        </h6>
                        <span className="text-secondary">bootdey</span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                        <h6 className="mb-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-facebook mr-2 icon-inline text-primary"
                          >
                            <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
                          </svg>
                          Facebook
                        </h6>
                        <span className="text-secondary">bootdey</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="card mb-3">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-3">
                          <h6 className="mb-0">Full Name</h6>
                        </div>
                        {isEditable ? (
                          <div className="col-sm-9 text-secondary">
                            <input
                              type="text"
                              defaultValue={fullName}
                              onChange={(e) => setFullName(e.target.value)}
                            />
                          </div>
                        ) : (
                          <div className="col-sm-9 text-secondary">
                            {fullName}
                          </div>
                        )}
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-sm-3">
                          <h6 className="mb-0">Username</h6>
                        </div>

                        <div className="col-sm-9 text-secondary">
                          {userName}
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-sm-3">
                          <h6 className="mb-0">Email</h6>
                        </div>
                        <div className="col-sm-9 text-secondary">{email}</div>
                      </div>
                      <hr />
                      {/* <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Phone</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      (239) 816-9029
                    </div>
                  </div>
                  <hr /> */}
                      <div className="row">
                        <div className="col-sm-3">
                          <h6 className="mb-0">Mobile</h6>
                        </div>
                        <div className="col-sm-9 text-secondary">{mobile}</div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-sm-3">
                          <h6 className="mb-0">Address</h6>
                        </div>
                        {isEditable ? (
                          <div className="col-sm-9 text-secondary">
                            <input
                              type="text"
                              defaultValue={address}
                              onChange={(e) => setAddress(e.target.value)}
                            />
                          </div>
                        ) : (
                          <div className="col-sm-9 text-secondary">
                            {address}
                          </div>
                        )}
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-sm-12">
                          <button
                            onClick={(e) => {
                              setIsEditable(!isEditable);
                              isEditable && updateProfile(e);
                            }}
                            className="btn btn-dark "
                            target="__blank"
                            href="https://www.bootdey.com/snippets/view/profile-edit-data-and-skills"
                          >
                            {isEditable ? "Update" : "Edit"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row gutters-sm">
                    <div className="col-sm-6 mb-3">
                      <div className="card h-100">
                        <div className="card-body">
                          <h6 className="d-flex align-items-center mb-3">
                            {/* <i className="material-icons text-info mr-2">
                          assignment
                        </i> */}
                            Course Status
                          </h6>
                          {courseStatus &&
                          Array?.isArray(courseStatus?.courseData) &&
                          courseStatus?.courseData.length > 0 ? (
                            courseStatus?.courseData.map((course) => {
                              let progress =
                                course.courseCompletionStatus >= 100
                                  ? 100
                                  : Math.floor(course?.courseCompletionStatus);
                              return (
                                <>
                                  <small>{course?.courseName}</small>
                                  <div className="progress mt-1">
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      aria-valuenow={`${progress}%`}
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                      style={{ width: `${progress}%` }}
                                    >
                                      {`${progress}%`}
                                    </div>
                                  </div>
                                </>
                              );
                            })
                          ) : (
                            <small>N.A</small>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default UserProfile;
